import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { pathOr } from "ramda";
import { Wrapper } from "../../components/Wrapper";
import ImageGallery from "../../components/ImageGallery";
import styled from "@emotion/styled";

export default function GalerijaSlikaPage({ data }) {
  return (
    <Layout>
      <SEO title="Galerija slika" />
      <Wrapper>
        <h1>Naše arhive</h1>
        {data.allWordpressPost.edges.map((edge, index) => {
          const images = pathOr(null, ["node", "acf", "gallery"], edge);

          return (
            images && (
              <ImageGalleryContainer key={index}>
                <h2>{edge.node.title}</h2>
                <ImageGallery thumbnails={images} images={images} />
              </ImageGalleryContainer>
            )
          );
        })}
      </Wrapper>
    </Layout>
  );
}

export const query = graphql`
  {
    allWordpressPost {
      edges {
        node {
          title
          acf {
            gallery {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ImageGalleryContainer = styled.div`
  margin-bottom: 20px;

  h2 {
    margin-bottom: 30px;
  }
`;
