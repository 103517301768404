import React from "react";
import PropTypes from "prop-types";
import SiteNav from "./header/SiteNav";
import IndexLayout from "./layouts/index";
import { outer, SiteNavMain, navSpace, inner } from "../styles/shared";
import { Footer } from "./Footer";

const Layout = ({ children, isHome }) => {
  return (
    <IndexLayout>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <div css={[outer, SiteNavMain]}>
        <div css={inner}>
          <SiteNav isHome={isHome} />
        </div>
      </div>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: isHome ? "" : 960,
          padding: `0 1.8rem 4rem`,
        }}
      >
        <main css={navSpace}>{children}</main>
      </div>
      <Footer />
    </IndexLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
