import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/core";
import Img from "gatsby-image/withIEPolyfill";
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";
import { Button } from "../../styles/shared";

class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingGallery: false,
      showedImagesNumber: 4,
      imageIndex: 0,
    };

    this.imageRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = e => {
    if (
      typeof e.target.className === "string" &&
      e.target.className.includes("LightboxContainer")
    ) {
      this.setState({ isShowingGallery: false });
    }
  };

  handleThumbnailClick = imageIndex => {
    this.setState({ isShowingGallery: true, imageIndex });
  };

  handleLeftButtonClick = () => {
    const imageIndex = (this.state.imageIndex -= 1);
    this.setState({ imageIndex: imageIndex < 0 ? 0 : imageIndex });
  };

  handleRightButtonClick = () => {
    const imageIndex = (this.state.imageIndex += 1);
    const lastIndex = this.props.images.length - 1;

    this.setState({
      imageIndex: imageIndex > lastIndex ? lastIndex : imageIndex,
    });
  };

  handleCloseGallery = () => {
    this.setState({ isShowingGallery: false });
  };

  handleOnExpand = () => {
    const imagesNumber = (this.state.showedImagesNumber += 8);
    const showedImagesNumber =
      imagesNumber > this.props.images.length ? this.props.images.length : imagesNumber;

    this.setState({ showedImagesNumber });
  };

  render() {
    const image = this.props.images[this.state.imageIndex];
    let clonedIndex = JSON.parse(JSON.stringify(this.state.imageIndex));
    const imageNumber = `${(clonedIndex += 1)}/${this.props.images.length}`;

    return (
      <Fragment>
        <ThumbnailContainer>
          <ThumbnailGridContainer>
            <ThumbnailGrid>
              {this.props.thumbnails &&
                this.props.thumbnails
                  .slice(0, this.state.showedImagesNumber)
                  .map((gallery, index) => {
                    return (
                      <figure
                        className="image"
                        key={index}
                        onClick={() => this.handleThumbnailClick(index)}
                      >
                        <Img fluid={gallery.localFile.childImageSharp.fluid} />
                      </figure>
                    );
                  })}
            </ThumbnailGrid>
          </ThumbnailGridContainer>
          {this.state.showedImagesNumber < this.props.images.length && (
            <Button onClick={this.handleOnExpand}>Više...</Button>
          )}
        </ThumbnailContainer>

        {this.state.isShowingGallery && image && (
          <ImageGalleryStyle>
            <LightboxContainer />
            <LightboxContent>
              <ImageCounter>{imageNumber}</ImageCounter>
              <LightboxImage ref={node => (this.imageRef = node)}>
                <Img className="image" fluid={image.localFile.childImageSharp.fluid} />
              </LightboxImage>
              <LeftButton onClick={this.handleLeftButtonClick}>
                <ArrowLeftOutlined />
              </LeftButton>
              <RightButton onClick={this.handleRightButtonClick}>
                <ArrowRightOutlined />
              </RightButton>
              <CloseButton onClick={this.handleCloseGallery}>
                <CloseOutlined />
              </CloseButton>
            </LightboxContent>
          </ImageGalleryStyle>
        )}
      </Fragment>
    );
  }
}
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const ImageGalleryStyle = styled.div`
  position: absolute;
`;

const ImageCounter = styled.div`
  position: fixed;
  top: 8%;
  color: lightgrey;
`;

const ThumbnailContainer = styled.div`
  text-align: center;

  button {
    margin-top: 20px;
    cursor: pointer;
  }
`;
const ThumbnailGridContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const ThumbnailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 0fr);
  grid-gap: 15px;
  overflow: hidden;

  @media (max-width: 940px) {
    grid-template-columns: repeat(2, 0fr);
  }

  @media (max-width: 460px) {
    grid-template-columns: repeat(1, 0fr);
  }

  .image {
    transition: 1s;

    .gatsby-image-wrapper {
      width: 200px;
      height: 200px;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: 1s;
      z-index: 99;
    }
  }
`;

const LightboxContainer = styled.div`
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;

  background: #1e1e1e;
  opacity: 0.96;

  &:after {
    animation: ${fadeIn} 0.2s ease-in-out;
    animation-iteration-count: 1;
  }
`;

const LightboxContent = styled.div`
  position: absolute;
  user-select: none;
  z-index: 99995;
`;

const LightboxImage = styled.div`
  width: 767.45px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .image {
    animation: ${fadeIn} 0.2s ease-in-out;
    animation-iteration-count: 1;
  }

  @media (max-width: 740px) {
    width: 100%;
  }
`;

const LeftButton = styled.div`
  position: fixed;
  top: 5%;
  left: 0;
  cursor: pointer;
  background: transparent;
  width: 100px;
  height: 100%;

  &:hover {
    span {
      color: white;
    }
  }

  span {
    color: gray;
    position: absolute;
    top: 40%;
    margin-left: 25px;
  }
`;

const RightButton = styled.div`
  position: fixed;
  top: 5%;
  right: 0;
  cursor: pointer;
  background: transparent;
  width: 100px;
  height: 100%;

  &:hover {
    span {
      color: white;
    }
  }

  span {
    color: gray;
    position: absolute;
    right: 0;
    top: 40%;
    margin-right: 25px;
  }
`;

const CloseButton = styled.div`
  position: fixed;
  right: 10px;
  top: 8%;
  color: gray;
  cursor: pointer;
  padding: 20px;
  z-index: 9999;

  &:hover {
    span {
      color: white;
    }
  }
`;

export default ImageGallery;
